import { useAlert } from 'react-alert';
import { useAppDispatch } from '../../app/hooks';
import { IMenuUnificationOrderResponse } from '../../types/brandMenu';

const useMenuUnificationOrderHandlers = () => {
  const dispatch = useAppDispatch();
  const alert = useAlert();

  const menuUnificationOrderFailureCallback = ({
    error,
  }: {
    error: string;
  }) => {
    alert.error(error, { timeout: 3000 });
  };

  const menuUnificationOrderSuccessCallback = ({
    data,
  }: {
    data: IMenuUnificationOrderResponse[];
  }) => {
    const message = 'Succeed to send order to menu module';
    alert.success(message, { timeout: 3000 });
  };

  return {
    menuUnificationOrderFailureCallback,
    menuUnificationOrderSuccessCallback,
  };
};

export default useMenuUnificationOrderHandlers;
