import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MENU_CACHE_LIMIT } from './cachedMenu.constants';
import {
  IAddBrandMenuData,
  IAddMenuData,
  ICachedMenuSlice,
  IUpdateBrandMenuData,
  IUpdateMenuData,
} from './cachedMenu.props';

const initialState: ICachedMenuSlice = {
  menusById: {},
  menusOrder: [],
  brandMenusById: {},
  brandMenusOrder: [],
};

const cachedMenuSlice = createSlice({
  name: 'cachedMenu',
  initialState,
  reducers: {
    resetCachedMenuData: () => {
      return { ...initialState };
    },
    addMenuData: (state, { payload }: PayloadAction<IAddMenuData>) => {
      const { restaurantId, menuVersion, menuData, menuTitle } = payload;
      if (state.menusOrder.length === MENU_CACHE_LIMIT) {
        // Remove the oldest cached menu restaurant data
        const oldestDetails = state.menusOrder.shift();
        if (oldestDetails?.restaurantId)
          delete state.menusById[oldestDetails.restaurantId];
      }
      state.menusOrder.push({ restaurantId, menuVersion, menuTitle });
      state.menusById[restaurantId] = menuData;
    },
    updateMenuData: (state, { payload }: PayloadAction<IUpdateMenuData>) => {
      const { restaurantId, menuVersion, menuData, index, menuTitle } = payload;
      state.menusOrder[index] = { restaurantId, menuVersion, menuTitle };
      state.menusById[restaurantId] = menuData;
    },
    addBrandMenuData: (
      state,
      { payload }: PayloadAction<IAddBrandMenuData>
    ) => {
      const { restaurantId, menuVersion, brandMenuData } = payload;
      if (state.brandMenusOrder.length === MENU_CACHE_LIMIT) {
        // Remove the oldest cached menu restaurant data
        const oldestDetails = state.brandMenusOrder.shift();
        if (oldestDetails?.restaurantId)
          delete state.menusById[oldestDetails.restaurantId];
      }
      state.brandMenusOrder.push({ restaurantId, menuVersion });
      state.brandMenusById[restaurantId] = brandMenuData;
    },
    updateBrandMenuData: (
      state,
      { payload }: PayloadAction<IUpdateBrandMenuData>
    ) => {
      const { restaurantId, menuVersion, brandMenuData, index } = payload;
      state.brandMenusOrder[index] = { restaurantId, menuVersion };
      state.brandMenusById[restaurantId] = brandMenuData;
    },
  },
});

export const {
  resetCachedMenuData,
  addMenuData,
  updateMenuData,
  addBrandMenuData,
  updateBrandMenuData,
} = cachedMenuSlice.actions;
export default cachedMenuSlice.reducer;
