import { EventSources } from '../constants/event';
import { ModalityType } from '../generated-interfaces/graphql';
import { CartItem } from '../utils/cart';
import { GenericMap } from '../utils/types';
import {EntityMenuItem, TransmissionMessage} from './messagingSlice';
import { LOYALTY_APPLY_STATUS } from './cartSlice.constants';
import { ISendItemProperties } from "./orderSlice.props";

export interface ISendLoyaltyData {
  check_id: string;
  store_id: string;
  request_id: string;
  session_id: string;
  source: EventSources;
  loyalty_code: string;
  seq_id: string;
}

export interface ILoyaltyTransmissionMessage extends TransmissionMessage {
  data: ISendLoyaltyData;
}

export type LoyaltyCouponItem = {
  name: string;
  couponno: string;
  modifiers: string[];
  isApplied?: boolean;
};

type LoyaltyRequest = {
  id: string;
  status: string;
  transactionId: string;
  loyaltyResponse: any;
  couponResponse: any;
};

export type Coupon = {
  id: string;
  code: string;
};

export interface IEntityCartItem extends EntityMenuItem {
  cartItemId: number;
}

export interface ICartState {
  cartItems: GenericMap<CartItem>;
  sequenceId: number;
  modality: ModalityType;
  cartItemsQuantity: Record<string, number>;
  loyaltyCouponItem?: LoyaltyCouponItem;
  currentTransactionId: string | null;
  currentSessionId: string | null;
  requests: {
    [id: string]: LoyaltyRequest;
  };
  coupons: GenericMap<Coupon>;
  loyaltyCode?: string;
  loyaltyApplyStatus?: LOYALTY_APPLY_STATUS;
  autoCombinedCartItems: GenericMap<CartItem>;
  autoCombinedCartItemsQuantity: Record<string, number>;
  hypothesisCartItems: GenericMap<IEntityCartItem>;
  posCartItems: ISendItemProperties[];
}

export const initialCartState: ICartState = {
  cartItems: {},
  sequenceId: 1,
  modality: ModalityType.Dinein,
  cartItemsQuantity: {},
  currentTransactionId: null,
  currentSessionId: null,
  requests: {},
  coupons: {},
  autoCombinedCartItems: {},
  autoCombinedCartItemsQuantity: {},
  hypothesisCartItems: {},
  posCartItems: [],
};
