import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { MouseEvent } from 'react';
import { useShallowSelector } from '../../app/hooks';
import { getCodeNameMapping } from '../../selectors/menu';
import { CartItem, CartModifierGroup } from '../../utils/cart';
import Colors from '../../utils/color';
import { DUMMY_MOD_SYMBOL } from '../../utils/constants';
import { getMenuItemPrice } from '../../utils/menu';
import { printCurrency } from '../../utils/money';
import CartItemModGroups from './CartItemModGroups';
import { getMenuUnificationFeatureFlag } from '../../utils/session-storage';
import { ParsedModifierGroup } from '../../types/menu';

interface IChildModGroup {
  modGroup: CartModifierGroup;
  quantity: number;
  cartItem: CartItem;
  isSelected?: boolean;
  selectModGroupToFocus: (e: MouseEvent, modGroup: ParsedModifierGroup) => void;
}

interface IChildModGroupStyling {
  hasChildren: boolean;
  isSelected?: boolean;
}

const useStyles = makeStyles<Theme, IChildModGroupStyling>((theme) => ({
  itemNameClass: {
    wordBreak: 'break-all',
    fontSize: `${theme.spacing(1.5)} !important`,
    backgroundColor: ({ isSelected }) => (isSelected ? Colors.alto : ''),
  },
  childModGroupClass: {
    fontSize: theme.spacing(1.5),
    paddingBottom: ({ hasChildren }) => (hasChildren ? theme.spacing(1) : 0),
  },
  itemPriceClass: {
    fontSize: `${theme.spacing(1.5)} !important`,
    paddingLeft: theme.spacing(2),
  },
}));

export default function ChildModGroup({
  modGroup,
  cartItem,
  quantity,
  isSelected,
  selectModGroupToFocus,
}: IChildModGroup) {
  const codeNameMapping = useShallowSelector(getCodeNameMapping);
  const { sortOrder, selectedItems, minimumSelections, name } = modGroup;

  let sortedSelectedItems = Object.values(selectedItems || {});
  if (!getMenuUnificationFeatureFlag()) {
    const modSortOrder = sortOrder.reduce(
      (acc: Record<string, number>, sortValue) => {
        const id: number = sortValue.id || 0;
        acc[id.toString()] = sortValue.sortOrder || 0;
        return acc;
      },
      {}
    );
    sortedSelectedItems.sort((a, b) => modSortOrder[a.id] - modSortOrder[b.id]);
  }

  const hasChildModifierGroups = !!sortedSelectedItems.find(
    (item) => Object.keys(item.childModifierGroups).length
  );
  const { itemNameClass, childModGroupClass, itemPriceClass } = useStyles({
    hasChildren: hasChildModifierGroups,
    isSelected,
  });

  return (
    <div
      className={`cartitem-child-modgroup ${childModGroupClass}`}
      onClick={(e) => selectModGroupToFocus(e, modGroup)}
    >
      {sortedSelectedItems.length < minimumSelections ? (
        <Typography
          color={Colors.Thunderbird}
          variant="subtitle2"
          children={'! ' + name}
        />
      ) : null}
      {sortedSelectedItems.map((item) => {
        const menuItemPrice = getMenuItemPrice(item, item.modality, quantity);
        return (
          <div key={item.id}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant="subtitle2" className={itemNameClass}>
                {item.modcode &&
                codeNameMapping[item.modcode].code !== DUMMY_MOD_SYMBOL
                  ? codeNameMapping[item.modcode].name + ' '
                  : ''}
                {item.name}
              </Typography>
              {menuItemPrice > 0 && (
                <Typography variant="subtitle2" className={itemPriceClass}>
                  {printCurrency(menuItemPrice, 'USD')}
                </Typography>
              )}
            </Box>
            {item && item.childModifierGroups && (
              <CartItemModGroups
                modGroupCartItem={item}
                cartItem={cartItem}
                quantity={quantity}
              />
            )}
          </div>
        );
      })}
    </div>
  );
}
