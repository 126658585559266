import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  IHealthScoreSlice,
  IUpdateCriticalCheck,
  IUpdateNonCriticalCheck,
} from './healthScore.props';
import {
  NonCriticalChecksNames,
  CriticalChecksNames,
} from './healthScore.constants';

export const initialState: IHealthScoreSlice = {
  criticalChecks: {
    [CriticalChecksNames.wsTaskRouter]: [],
    [CriticalChecksNames.wsRestaurantEvents]: [],
    [CriticalChecksNames.wsRestaurantAudio]: [],

    [CriticalChecksNames.apiLogin]: [],
    [CriticalChecksNames.apiFetchMenu]: [],
    [CriticalChecksNames.apiFetchMenuURL]: [],
    [CriticalChecksNames.apiFetchMenuVersions]: [],
  },
  nonCriticalChecks: {
    [NonCriticalChecksNames.apiRestaurantInfo]: [],
    [NonCriticalChecksNames.apiRestaurantDiagnostic]: [],
    [NonCriticalChecksNames.apiAIStatus]: [],
    [NonCriticalChecksNames.apiSessionBuffer]: [],
  },
};

const healthScoreSlice = createSlice({
  name: 'healthScore',
  initialState,
  reducers: {
    resetHealthScoreData: () => {
      return { ...initialState };
    },
    updateCriticalCheckScore: (
      state,
      { payload }: PayloadAction<IUpdateCriticalCheck>
    ) => {
      const { name, value } = payload;
      state.criticalChecks[name]?.push(value);
    },
    updateNonCriticalCheckScore: (
      state,
      { payload }: PayloadAction<IUpdateNonCriticalCheck>
    ) => {
      const { name, value } = payload;
      state.nonCriticalChecks[name]?.push(value);
    },
  },
});

export const {
  resetHealthScoreData,
  updateCriticalCheckScore,
  updateNonCriticalCheckScore,
} = healthScoreSlice.actions;

export default healthScoreSlice.reducer;
