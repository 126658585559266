interface ICodeName {
    code: string,
    name: string,
}

export const UNIFIED_MENU_CODE_NAME_MAPPING:Record<string, ICodeName> = {
  '1': {
    code: '~',
    name: 'light',
  },
  '2': {
    code: '-',
    name: 'no',
  },
  '3': {
    code: '++',
    name: 'extra',
  },
  '4': {
    code: '=',
    name: 'onside',
  },
};

export const UNIFIED_MENU_MOD_SYMBOL_MAPPING:Record<string, ICodeName> = {
  '.': {
    code: '0',
    name: 'dummy modCode',
  },
  '~': {
    code: '1',
    name: 'light',
  },
  '-': {
    code: '2',
    name: 'no',
  },
  '++': {
    code: '3',
    name: 'extra',
  },
  '=': {
    code: '4',
    name: 'onside',
  },
};
