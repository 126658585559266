import { PropertyType } from '../../../generated-interfaces/graphql';
import { ParsedMenuItem } from '../../../types/menu';

export const DUMMY_MENU_ITEM: ParsedMenuItem = {
  id: '1594877',
  name: 'dummyItem',
  price: 0,
  tax: null,
  description: '',
  imageUrl: '/no_menu_image.jpg',
  available: true,
  unavailableUntil: null,
  isModifierOnly: false,
  customRequestAvailable: false,
  modifierGroups: {},
  menuOverrides: [],
  menuItemSettings: {
    '18501': {
      id: '18501',
      key: 'IS_TO_GO_ENABLED',
      value: 'false',
      menuItemId: 1594877,
    },
    '18502': {
      id: '18502',
      key: 'IS_DINE_IN_ENABLED',
      value: 'true',
      menuItemId: 1594877,
    },
    '18503': {
      id: '18503',
      key: 'IS_DELIVERY_ENABLED',
      value: 'false',
      menuItemId: 1594877,
    },
  },
  parentCategoryIds: [24877],
  posProperties: {
    '18504': {
      id: '18504',
      propertyType: PropertyType.MenuItem,
      objectPrimaryKey: '1594877',
      key: 'pos_id',
      value: '11014',
    },
  },
  sortOrder: [],
  availableLimitedQuantity: null,
  category: 'Sides',
  categoryId: '24877',
  itemId: '1594877-24877',
  originalMenuItemId: '1594877',
  containsOwnModifierGroup: false,
  sortOrderByCategory: 8,
  sortOrderByModifierGroup: null,
  overrides: {},
};

export const DUMMY_MODIFIER_GROUP = {
  id: '462595',
  name: 'Select Small Drink',
  description: null,
  minimumSelections: 1,
  maximumSelections: 1,
  defaultSelectedItemIds: [],
  menuItems: {},
  posProperties: [],
  sortOrder: [
    {
      id: 9576208,
      sortOrder: 1,
    },
  ],
  voiceProperties: {},
  prpName: 'Select Small Drink',
};

export const DUMMY_CATEGORY = {
  id: '',
  name: '',
  timePeriods: [],
  description: null,
  menuItems: [],
  ownSortOrder: 1,
  sortOrder: [
    {
      id: 1594747,
      sortOrder: 0,
    },
  ],
};
