import { useEffect, useRef } from 'react';
import logger from '../../utils/logger';
import { useAppDispatch } from '../../app/hooks';
import { setWSConnectionMessage } from '../../redux/features/taskRouter/taskRouter.slice';
import { updateCriticalCheckScore } from '../../redux/features/healthScore/healthScore.slice';
import { CriticalChecksNames } from '../../redux/features/healthScore/healthScore.constants';

const useTaskRouterWSActions = () => {
  const dispatch = useAppDispatch();
  const didUnmount = useRef(false);

  useEffect(() => {
    return () => {
      didUnmount.current = true;
    };
  }, []);

  const onOpen = () => {
    logger.log({
      message: 'Successfully established task router websocket connection',
    });
    dispatch(
      updateCriticalCheckScore({
        name: CriticalChecksNames.wsTaskRouter,
        value: 1,
      })
    );
  };

  const onError = () => {
    const errorMessage =
      'There was an error connecting to task-router websocket - Retrying...';
    logger.error({
      message: errorMessage,
    });
    //@ts-ignore
    window.newrelic?.noticeError(errorMessage);
    dispatch(setWSConnectionMessage(errorMessage));
  };

  const shouldReconnect = (closeEvent: any) => {
    const isClosedManually = closeEvent.code === 1005;
    return isClosedManually ? false : didUnmount.current === false;
  };

  const onReconnectStop = (numAttempted: number) => {
    const errorMessage = `Unable to establish task-router websocket connection. Maximum reconnect attempts(${numAttempted}) reached`;
    logger.error({
      message: errorMessage,
    });
    //@ts-ignore
    window.newrelic?.noticeError(errorMessage);
    dispatch(setWSConnectionMessage(errorMessage));
    dispatch(
      updateCriticalCheckScore({
        name: CriticalChecksNames.wsTaskRouter,
        value: 0,
      })
    );
  };

  return {
    onOpen,
    onError,
    shouldReconnect,
    onReconnectStop,
  };
};

export default useTaskRouterWSActions;
