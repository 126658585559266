import { ICachedMenuSlice } from '../redux/features/cachedMenu/cachedMenu.props';

export const getMenuVersion = (args: {
  menu: any;
  restaurant: any;
  taskRouter: any;
  cachedMenu: ICachedMenuSlice;
}) => {
  const { menu, restaurant, taskRouter, cachedMenu } = args;
  const { selectedMenuVersion, prodLiveVersion, menuVersions } = menu || {};

  let prpMenuVersion = '',
    prpMenuTitle = '';

  if (taskRouter?.currentTaskId) {
    const restaurantCode = restaurant.selectedRestaurantDetails?.restaurantCode;
    const cachedEntry = cachedMenu.menusOrder?.find(
      ({ restaurantId }) => restaurantId === restaurantCode
    );

    const { menuVersion = '', menuTitle = '' } = cachedEntry || {};
    prpMenuVersion = menuVersion;
    prpMenuTitle = menuTitle;
  } else if (['', 'latest'].includes(selectedMenuVersion)) {
    const { commitId = '', comment = '' } = prodLiveVersion || {};

    prpMenuVersion = commitId;
    prpMenuTitle = comment;
  } else if (selectedMenuVersion) {
    prpMenuVersion = selectedMenuVersion;
    prpMenuTitle =
      menuVersions.find(
        ({ commitId }: { commitId: number }) => commitId === selectedMenuVersion
      )?.comment || '';
  }

  return { prpMenuVersion, prpMenuTitle };
};
