import { Checkbox, Chip, FormControlLabel } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { memo } from 'react';
import Colors from '../../../utils/color';

export interface IModChoiceStyleProps {
  is86edToday: boolean;
}

const useStyles = makeStyles<IModChoiceStyleProps>()(
  ({ spacing }, { is86edToday }) => {
    const chipColor = is86edToday ? Colors.red : '';
    const chipTextDecoration = is86edToday ? 'line-through' : '';
    return {
      button: {
        display: 'none !important',
      },
      chipContainer: {
        borderRadius: `${spacing(0.5)} !important`,
        fontWeight: 'bold !important',
        textTransform: 'uppercase',
        height: `${spacing(5)} !important`,
        width: '100%',
        '& .MuiChip-label': {
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          whiteSpace: 'normal',
          textAlign: 'center',
          lineHeight: '1.2',
        },
      },
      controlLabel: {
        width: '18%',
        margin: `0px ${spacing(1.125)} ${spacing(1.25)} 0px !important`,
        height: `${spacing(5)}`,
        display: 'inline-block !important',
        '& .MuiFormControlLabel-label': {
          display: 'inline-block !important',
          width: '100%',
        },
        '& .MuiChip-root.MuiChip-filled': {
          backgroundColor: Colors.mineShaft,
          color: Colors.white,
        },
        '& .MuiChip-root': {
          cursor: 'pointer',
          fontSize: spacing(1.25),
          color: chipColor,
          textDecoration: chipTextDecoration,
        },
      },
    };
  }
);

interface IMultipleModChoice {
  id: string;
  name: string;
  modLabel: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  is86edToday: boolean;
  isSelected: boolean;
}

const MultipleModChoice = ({
  id,
  name,
  modLabel,
  onChange,
  is86edToday,
  isSelected,
}: IMultipleModChoice) => {
  const { classes } = useStyles({ is86edToday });

  const ctrl = (
    <Checkbox
      checked={isSelected}
      color="primary"
      value={id}
      onChange={onChange}
      name={id}
      className={classes.button}
    />
  );
  const label = (
    <Chip
      size="small"
      title={name}
      label={modLabel}
      variant={isSelected ? 'filled' : 'outlined'}
      className={classes.chipContainer}
    />
  );

  return (
    <FormControlLabel
      control={ctrl}
      label={label}
      className={classes.controlLabel}
    />
  );
};

export default memo(MultipleModChoice);
