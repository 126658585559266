import { metrics } from '@opentelemetry/api';
import { OTEL_APP_NAME, OTEL_METRIC_HEALTH } from '../constants';

const useOTELMetrics = () => {
  const meter = metrics.getMeter(OTEL_APP_NAME);
  const healthMetric = meter.createGauge(OTEL_METRIC_HEALTH, {
    description: 'Health score of HITL application',
    unit: 'unit',
  });

  const stopSendingMetrics = () => {
    metrics
      .getMeterProvider()
      // @ts-ignore:next-line
      ?.shutdown?.()
      .then(() => metrics.disable());
  };

  return {
    healthMetric,
    stopSendingMetrics,
  };
};

export default useOTELMetrics;
