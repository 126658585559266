import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import {
  getAllInvalidModGroups,
  getValidCartItems,
} from '../utils/cart';

const selectCart = (state: RootState) => state.cart;
const selectCartModality = createSelector(selectCart, (cart) => cart.modality);
const selectCartItems = createSelector(selectCart, (cart) => cart.cartItems);
const selectCartItemsQuantity = createSelector(
  selectCart,
  (cart) => cart.cartItemsQuantity
);
const invalidModGroupsSelector = createSelector(selectCartItems, (cartItems) =>
  getAllInvalidModGroups(cartItems)
);
const selectValidCartItems = createSelector(selectCartItems, (cartItems) =>
  getValidCartItems(cartItems)
);
const selectHypothesisCartItems = createSelector(selectCart, (cart) => cart.hypothesisCartItems);

const selectPOSCartItems = createSelector(selectCart, (state) => state.posCartItems);

export {
  selectCart,
  selectCartItems,
  selectCartItemsQuantity,
  invalidModGroupsSelector,
  selectCartModality,
  selectValidCartItems,
  selectHypothesisCartItems,
  selectPOSCartItems
};
