import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../app/hooks';
import {
  selectDialogSelectedModGroup,
  selectSelectedItemCartId,
} from '../../selectors/dialog';
import { CartItem } from '../../utils/cart';
import Colors from '../../utils/color';
import {
  convertModGroupToMap,
  sortModGroups,
} from '../../utils/menu';
import CartItemChildModGroup from './CartItemChildModGroup';
import { dialogActions } from '../../reducers/dialogSlice';
import { convertToMap } from '../../utils';
import { ParsedModifierGroup } from '../../types/menu';

interface ICartItemModGroups {
  cartItem: CartItem;
  modGroupCartItem: CartItem;
  quantity: number;
  selectable?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  cartItemModGroupClass: {
    paddingLeft: theme.spacing(1.75),
  },
  modGroupErrorClass: {
    fontSize: `${theme.spacing(1.5)} !important`,
  },
  selectedModGroupClass: {
    backgroundColor: Colors.alto,
  },
}));

export default function CartItemModGroups({
  modGroupCartItem,
  cartItem,
  quantity,
  selectable,
}: ICartItemModGroups) {
  const dispatch = useAppDispatch();
  const selectedModGroup = useSelector(selectDialogSelectedModGroup);
  const selectedItemCartId = useSelector(selectSelectedItemCartId);
  const { cartItemModGroupClass, modGroupErrorClass, selectedModGroupClass } =
    useStyles();
  const childModifierGroups = convertModGroupToMap(
    Object.values({
      ...modGroupCartItem.childModifierGroups,
    }).filter((modGroup) => Object.keys(modGroup.selectedItems).length)
  );

  const childModifierGroupIDs = Object.keys(childModifierGroups);

  const requiredButNotSelectedModifierGroups = convertToMap(
    Object.values(modGroupCartItem.modifierGroups).filter(
      (modGroup) =>
        modGroup.minimumSelections > 0 &&
        !childModifierGroupIDs.includes(modGroup.id)
    )
  );
  const requiredButNotSelectedModifierGroupIDs = Object.keys(
    requiredButNotSelectedModifierGroups
  );

  const selectModGroupToFocus = (
    e: MouseEvent,
    modGroup: ParsedModifierGroup
  ) => {
    if (!selectable) return;

    e.stopPropagation();
    if (selectedItemCartId !== cartItem.cartItemId)
      dispatch(
        dialogActions.updateSelectedItem({
          item: cartItem,
          itemCartId: cartItem.cartItemId,
        })
      );
    dispatch(dialogActions.updateSelectedModGroup(modGroup));
  };

  return (
    <div className={`cartitem-mod-group ${cartItemModGroupClass}`}>
      {sortModGroups(modGroupCartItem).map((modGroup) => {
        const isSelected =
          selectedItemCartId === cartItem.cartItemId &&
          selectedModGroup?.id === modGroup.id;
        if (childModifierGroupIDs.includes(modGroup.id))
          return (
            <CartItemChildModGroup
              key={modGroup.id}
              quantity={quantity}
              cartItem={cartItem}
              modGroup={childModifierGroups[modGroup.id]}
              isSelected={isSelected}
              selectModGroupToFocus={selectModGroupToFocus}
            />
          );
        if (requiredButNotSelectedModifierGroupIDs.includes(modGroup.id))
          return (
            <div
              key={modGroup.id}
              onClick={(e) => selectModGroupToFocus(e, modGroup)}
              className={isSelected ? selectedModGroupClass : undefined}
            >
              <Typography
                className={modGroupErrorClass}
                color={Colors.Thunderbird}
                variant="subtitle2"
                children={'! ' + modGroup.name}
              />
            </div>
          );
        return null;
      })}
    </div>
  );
}
