import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector, useShallowSelector } from '../../app/hooks';
import { CartItemReview } from '../../components/OrderReview/CartItemReview';
import CouponCodeReview from '../../components/OrderReview/CouponCodeReview';
import LoyaltyCouponItemReview from '../../components/OrderReview/LoyaltyCouponItemReview';
import {
  addCouponToCart,
  deleteCoupon,
  deleteHypothesisItem,
  setLoyaltyApplyStatus,
  setPosCartItems
} from "../../reducers/cartSlice";
import {
  InfoTransmissionMessage,
  messagingActions,
} from '../../reducers/messagingSlice';
import { sendOrder } from '../../reducers/orderSlice';
import { setCartEmpty } from '../../redux/features/sessionBoundary/sessionBoundary.slice';
import { selectValidCartItems } from '../../selectors/cart';
import {
  selectCurrentTransactionCoupons,
  selectIsPosmonReconnected,
} from '../../selectors/order';
import { isValidCartSelector } from '../../utils/cart';
import { formAutoCombos } from '../../reducers/menuSlice';
import { selectFeatureFlagAutoCombo } from '../../redux/features/config/config.selector';
import {
  selectAutoComboCartItems,
  selectAutoComboCartItemsQuantity,
} from '../../redux/features/autoCombo/autoCombo.selector';
import HypothesisItemReview from '../../components/OrderReview/HypothesisItemReview';
import { selectIsAIAutoMode } from '../../redux/features/ai/ai.selector';
import { getMenuUnificationFeatureFlag } from '../../utils/session-storage';
import useMenuUnificationOrderHandlers from '../../hooks/menuUnification/useMenuUnificationOrderHandler.hooks';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingBottom: '110px',
  },
}));

function OrderReview() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    cartItems,
    cartItemsQuantity,
    loyaltyCouponItem,
    loyaltyApplyStatus,
    loyaltyCode,
    coupons,
    hypothesisCartItems,
  } = useShallowSelector((state) => state.cart);
  const cartValid = useSelector(isValidCartSelector);
  const validCartItems = useSelector(selectValidCartItems);
  const isPosmonReconnected = useAppSelector(selectIsPosmonReconnected);
  const couponsToArray = Object.values(coupons);
  const autoComboEnabled = useAppSelector(selectFeatureFlagAutoCombo);
  const autoCombinedCartItems = useAppSelector(selectAutoComboCartItems);
  const autoCombinedCartItemsQuantity = useAppSelector(
    selectAutoComboCartItemsQuantity
  );
  const isAIMode = useAppSelector(selectIsAIAutoMode);
  const isAutoCombined =
    autoComboEnabled && Object.values(autoCombinedCartItems).length > 0;

  const {
    menuUnificationOrderFailureCallback,
    menuUnificationOrderSuccessCallback,
  } = useMenuUnificationOrderHandlers();

  useEffect(() => {
    if (Object.values(validCartItems).length > 0 && !autoComboEnabled) {
      dispatch(
        sendOrder({
          ...(getMenuUnificationFeatureFlag()
            ? {
                menuUnificationOrderFailureCallback,
                menuUnificationOrderSuccessCallback,
              }
            : {}),
        })
      );
    } else if (autoComboEnabled && !isAIMode) {
      // Handle send order inside formAutoCombos when auto combo is enabled
      dispatch(formAutoCombos());
    }
  }, [
    cartItems,
    cartItemsQuantity,
    cartValid,
    validCartItems,
    coupons,
    isAIMode,
    autoComboEnabled,
  ]);

  useEffect(() => {
    if (
      Object.values(validCartItems).length > 0 &&
      loyaltyCouponItem &&
      !loyaltyCouponItem.isApplied
    ) {
      dispatch(
        sendOrder({
          ...(getMenuUnificationFeatureFlag()
            ? {
                menuUnificationOrderFailureCallback,
                menuUnificationOrderSuccessCallback,
              }
            : {}),
        })
      );
    }
  }, [loyaltyCouponItem]);

  useEffect(() => {
    if (Object.values(validCartItems).length > 0 && isPosmonReconnected) {
      dispatch(
        sendOrder({
          ...(getMenuUnificationFeatureFlag()
            ? {
                menuUnificationOrderFailureCallback,
                menuUnificationOrderSuccessCallback,
              }
            : {}),
        })
      );
    }
  }, [isPosmonReconnected]);

  useEffect(() => {
    dispatch(setCartEmpty(!Object.values(cartItems).length));
    if (getMenuUnificationFeatureFlag() && !Object.values(cartItems).length) {
      dispatch(setPosCartItems([]));
    }
  }, [cartItems]);

  const cartItemsState = useAppSelector(
    (state) => state.order.currentTransactionItems
  );

  const currentTransactionCoupons = useAppSelector(
    selectCurrentTransactionCoupons
  );

  const removeLoyalty = () => {
    dispatch(setLoyaltyApplyStatus(undefined));
  };

  const removeCoupon = (id: string) => {
    dispatch(deleteCoupon(id));
  };

  const cloneCoupon = (code: string) => {
    const payload: Partial<InfoTransmissionMessage> = {
      data: { message: 'Coupon ' + code + ' added', type: 'METRIC' },
    };
    dispatch(messagingActions.sendInfo(payload as any));
    dispatch(addCouponToCart(code));
  };

  const getCartItemReview = () => {
    const cartItemsToUse = isAutoCombined ? autoCombinedCartItems : cartItems;
    const cartItemsQuantityToUse = isAutoCombined
      ? autoCombinedCartItemsQuantity
      : cartItemsQuantity;
    const cartItemsIds = Object.keys(cartItemsToUse);
    const hypothesisCartItemsIds = Object.keys(hypothesisCartItems);
    return [...cartItemsIds, ...hypothesisCartItemsIds]
      .map((id) => Number(id))
      .sort((a, b) => a - b)
      .map((cartItemIdInNum) => {
        const cartItemId = cartItemIdInNum.toString();
        if (cartItemsIds.includes(cartItemId)) {
          return (
            <CartItemReview
              key={cartItemId}
              status={cartItemsState[cartItemId]}
              cartItem={cartItemsToUse[cartItemId]}
              quantity={cartItemsQuantityToUse[cartItemId]}
            />
          );
        } else {
          return (
            <HypothesisItemReview
              key={cartItemId}
              hypothesisCartItem={hypothesisCartItems[cartItemId]}
              handleHypothesisItemRemove={removeHypothesisItem}
              status={cartItemsState[cartItemId]}
            />
          );
        }
      });
  };

  const removeHypothesisItem = (id: string) => {
    dispatch(deleteHypothesisItem(id));
  };

  return (
    <div id="orderReview" className={classes.container}>
      {/* Display loyalty coupon Item */}
      {loyaltyCouponItem && (
        <LoyaltyCouponItemReview item={loyaltyCouponItem} />
      )}

      {/* Display loyalty coupon */}
      {!loyaltyCouponItem && loyaltyApplyStatus && loyaltyCode && (
        <CouponCodeReview
          couponCode={loyaltyCode}
          loyaltyApplyStatus={loyaltyApplyStatus}
          handleLoyaltyRemove={removeLoyalty}
        />
      )}

      {/* Display coupon code */}
      {couponsToArray.length > 0 &&
        couponsToArray.map(({ id, code }) => (
          <CouponCodeReview
            key={id}
            couponCode={code}
            couponId={id}
            status={currentTransactionCoupons[id]}
            handleCouponRemove={removeCoupon}
            handleCouponDuplicate={cloneCoupon}
          />
        ))}
      {getCartItemReview()}
    </div>
  );
}

export default memo(OrderReview);
