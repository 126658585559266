export enum CriticalChecksNames {
  wsTaskRouter = 'ws_taskrouter',
  wsRestaurantEvents = 'ws_restaurant_events',
  wsRestaurantAudio = 'ws_restaurant_audio',

  apiLogin = 'api_login',
  apiFetchMenu = 'api_fetch_menu',
  apiFetchMenuURL = 'api_fetch_menu_url',
  apiFetchMenuVersions = 'api_fetch_menu_versions',
}

export enum NonCriticalChecksNames {
  apiRestaurantInfo = 'api_fetch_restaurant_info',
  apiRestaurantDiagnostic = 'api_fetch_diagnostics',
  apiAIStatus = 'api_ai_status',
  apiSessionBuffer = 'api_session_buffer',
}
