import {
  useFlag,
  useUnleashContext,
  useVariant,
} from '@unleash/proxy-client-react';
import { useEffect } from 'react';
import { useAppDispatch } from '../../app/hooks';
import { update } from '../../redux/features/config/config.slice';
import { useSelector } from 'react-redux';
import { selectActiveRestaurantCode } from '../../selectors/restaurant';
import {
  setAuthRefactorFeatureFlag,
  setMenuUnificationFeatureFlag,
} from '../../utils/session-storage';
import { setAgentInactivityFeatureFlag } from '../../utils/session-storage';

const useUnleashHooks = () => {
  const updateContext = useUnleashContext();
  const audioBackend = useFlag('audio_backend');
  const autoCombo = useFlag('auto_combo');
  const authRefactor = useFlag('auth_refactor');
  const agentInactivityRefactor = useFlag('agent_inactivity_refactor');
  const unifiedMenuVariant = useVariant('unified_menu');

  const dispatch = useAppDispatch();
  const restaurantCode = useSelector(selectActiveRestaurantCode);

  useEffect(() => {
    dispatch(update({ FEATURE_FLAG_AUDIO_BACKEND: audioBackend }));
  }, [audioBackend]);

  useEffect(() => {
    dispatch(update({ FEATURE_FLAG_AUTO_COMBO: autoCombo }));
  }, [autoCombo]);

  useEffect(() => {
    setAgentInactivityFeatureFlag((!!agentInactivityRefactor).toString());
  }, [agentInactivityRefactor]);

  useEffect(() => {
    const updateRestaurantCode = async () => {
      await updateContext({ userId: restaurantCode });
    };
    updateRestaurantCode();
  }, [restaurantCode]);

  useEffect(() => {
    setAuthRefactorFeatureFlag((!!authRefactor).toString());
  }, [authRefactor]);

  // Restaurants which are selected for unified menu are fetched via unleash variant and stored in config
  // This config will inturn be used for populating menu unification feature flag on demand basis across the application
  useEffect(() => {
    const {
      name: unifiedMenuVariantName,
      payload: unifiedMenuVariantPayload,
      enabled: isUnifiedMenuEnabled,
    } = unifiedMenuVariant || {};

    if (
      unifiedMenuVariantName === 'unified_menu_restaurants' &&
      isUnifiedMenuEnabled
    ) {
      const unifiedMenuRestaurants =
        unifiedMenuVariantPayload?.value?.split(',') || [];
      dispatch(
        update({
          UNIFIED_MENU_RESTAURANTS: unifiedMenuRestaurants,
        })
      );
      setMenuUnificationFeatureFlag(
        unifiedMenuRestaurants.includes(restaurantCode).toString()
      );
    } else {
      dispatch(update({ UNIFIED_MENU_RESTAURANTS: [] }));
      setMenuUnificationFeatureFlag('false');
    }
  }, [unifiedMenuVariant]);
};

export default useUnleashHooks;
