import { Resource } from '@opentelemetry/resources';
import { metrics } from '@opentelemetry/api';
import {
  MeterProvider,
  PeriodicExportingMetricReader,
} from '@opentelemetry/sdk-metrics';
import { OTLPMetricExporter } from '@opentelemetry/exporter-metrics-otlp-http/build/src/platform/browser';
import { OTEL_APP_NAME } from './constants';

const setupOTELSDK = () => {
  const resource = Resource.default().merge(
    new Resource({
      'service.name': OTEL_APP_NAME,
    })
  );

  const metricExporter = new OTLPMetricExporter({
    url: process.env.REACT_APP_OTEL_EXPORTER_OTLP_ENDPOINT,
    headers: {
      Authorization: `Basic ${process.env.REACT_APP_OTEL_EXPORTER_OTLP_HEADERS}`,
    },
  });

  const metricReader = new PeriodicExportingMetricReader({
    exporter: metricExporter,
    exportIntervalMillis: 60000,
  });

  const meterProvider = new MeterProvider({
    resource: resource,
    readers: [metricReader],
  });

  metrics.setGlobalMeterProvider(meterProvider);
};

export { setupOTELSDK };
