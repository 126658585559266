import { useAlert } from 'react-alert';
import { useDispatch } from 'react-redux';
import { messagingActions } from '../../reducers/messagingSlice';
import { userActions } from '../../reducers/userSlice';

const DELAY = 2000;
const useLogout = ({
  stopSendingMetrics,
}: {
  stopSendingMetrics: Function;
}) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const clearSession = () => {
    stopSendingMetrics();
    dispatch(messagingActions.closeConnection());
    dispatch(messagingActions.closeAudioWSConnection());
    dispatch(userActions.logout());
  };
  const logout = (message?: string) => {
    if (message) {
      alert.info(message, { timeout: DELAY, onClose: clearSession });
    } else {
      clearSession();
    }
  };

  return {
    logout,
  };
};

export default useLogout;
