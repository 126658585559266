import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NodeEnv } from '../../../constants/enums';
import { readEnvVariable } from '../../../utils';

export interface ConfigState {
  NODE_ENV: NodeEnv;
  ITEM_BY_ITEM: boolean;
  DISABLE_INTENT_INTERVAL: number;
  FEATUREFLAG_TASKROUTER: boolean;
  TASKROUTER_WAITING_TIME: number;
  FEATURE_FLAG_MENU_VERSION: boolean;
  TASKROUTER_NOTIFICATION_SOUND: boolean;
  TASK_ROUTER_AGENT_AUTO_ASSIGNMENT: boolean;
  WEBSOCKET_STAGE_URL: string;
  WEBSOCKET_STAGE_BASIC_AUTH_PARAM: string;
  WEBSOCKET_URL: string;
  WEBSOCKET_BASIC_AUTH_PARAM: string;
  WEBSCOCKET_URL_INCLUDE_AUTH_PARAM: boolean;
  PRP_PERSISTENT_API: string;
  PRP_API: string;
  MENU_API: string;
  RESTAURANT_DIAGNOSTIC_API: string;
  PAGERDUTY_INTEGRATION_KEY: string;
  SESSION_BUFFER_API: string;
  SESSION_BUFFER_API_AUTHENTICATION: string;
  AUTH_URL: string;
  RESTAURANT_SERVICE_URL: string;
  AI_API: string;
  EVENT_API: string;
  EVENT_API_BASIC_AUTH_PARAM: string;
  WEBSOCKET_RETRY_MAX_BACKOFF: number;
  AGENT_HEARTBEAT_EVENT_INTERVAL: number;
  ALERT_TIMEOUT: number;
  FEATURE_FLAG_AUDIO_BACKEND: boolean;
  INTERCEPTION_REASONS_TIMER: number;
  INTERCEPTION_OTHER_REASON_TIMER: number;
  FINISH_ORDER_TIMER: number;
  AGENT_INACTIVITY_TIMER: number;
  ORDER_SUBMISSION_ALERT_TIMEOUT: number;
  FEATURE_FLAG_AUTO_COMBO: boolean;
  MU_MENU_API: string;
  MU_MENU_API_TOKEN: string;
  UNIFIED_MENU_RESTAURANTS?: Array<string>;
}

export const initialState: ConfigState = {
  NODE_ENV:
    process.env.REACT_APP_DEPLOY_ENV?.toUpperCase() ||
    (process.env.NODE_ENV.toUpperCase() as any),
  ITEM_BY_ITEM:
    (process.env.REACT_APP_ITEM_BY_ITEM
      ? process.env.REACT_APP_ITEM_BY_ITEM
      : 'FALSE'
    ).toUpperCase() === 'TRUE',
  DISABLE_INTENT_INTERVAL: 3000, // in milliseconds,
  FEATUREFLAG_TASKROUTER:
    (process.env.REACT_APP_FEATUREFLAG_TASKROUTER || 'FALSE').toUpperCase() ===
    'TRUE',
  TASKROUTER_NOTIFICATION_SOUND:
    (
      process.env.REACT_APP_TASKROUTER_NOTIFICATION_SOUND || 'TRUE'
    ).toUpperCase() === 'TRUE',
  TASKROUTER_WAITING_TIME: process.env.REACT_APP_TASKROUTER_WAITING_TIME
    ? parseInt(process.env.REACT_APP_TASKROUTER_WAITING_TIME)
    : 5000,
  FEATURE_FLAG_MENU_VERSION:
    readEnvVariable('FEATURE_FLAG_MENU_VERSION').toUpperCase() === 'TRUE',
  WEBSOCKET_STAGE_URL: readEnvVariable('WEBSOCKET_STAGE_URL') || '',
  WEBSOCKET_STAGE_BASIC_AUTH_PARAM:
    readEnvVariable('WEBSOCKET_STAGE_BASIC_AUTH_PARAM') || '',
  WEBSOCKET_URL: readEnvVariable('WEBSOCKET_URL') || '',
  WEBSOCKET_BASIC_AUTH_PARAM:
    readEnvVariable('WEBSOCKET_BASIC_AUTH_PARAM') || '',
  WEBSCOCKET_URL_INCLUDE_AUTH_PARAM:
    readEnvVariable('WEBSCOCKET_URL_INCLUDE_AUTH_PARAM').toUpperCase() ===
    'TRUE',
  PRP_PERSISTENT_API: readEnvVariable('PRP_PERSISTENT_API') || '',
  PRP_API: readEnvVariable('PRP_API') || '',
  MENU_API: readEnvVariable('MENU_API') || '',
  RESTAURANT_DIAGNOSTIC_API: readEnvVariable('RESTAURANT_DIAGNOSTIC_API') || '',
  PAGERDUTY_INTEGRATION_KEY: readEnvVariable('PAGERDUTY_INTEGRATION_KEY') || '',
  SESSION_BUFFER_API: readEnvVariable('SESSION_BUFFER_API') || '',
  SESSION_BUFFER_API_AUTHENTICATION:
    readEnvVariable('SESSION_BUFFER_API_AUTHENTICATION') || '',
  AUTH_URL: readEnvVariable('AUTH_URL') || '',
  RESTAURANT_SERVICE_URL: readEnvVariable('RESTAURANT_SERVICE_URL') || '',
  AI_API: readEnvVariable('AI_API') || '',
  TASK_ROUTER_AGENT_AUTO_ASSIGNMENT:
    readEnvVariable('TASK_ROUTER_AGENT_AUTO_ASSIGNMENT').toUpperCase() ===
    'TRUE',
  EVENT_API: readEnvVariable('EVENT_API') || '',
  EVENT_API_BASIC_AUTH_PARAM:
    readEnvVariable('EVENT_API_BASIC_AUTH_PARAM') || '',
  WEBSOCKET_RETRY_MAX_BACKOFF: process.env.REACT_APP_WEBSOCKET_RETRY_MAX_BACKOFF
    ? parseInt(process.env.REACT_APP_WEBSOCKET_RETRY_MAX_BACKOFF)
    : 10000,
  AGENT_HEARTBEAT_EVENT_INTERVAL:
    parseInt(readEnvVariable('AGENT_HEARTBEAT_EVENT_INTERVAL')) || 5000,
  ALERT_TIMEOUT: process.env.REACT_APP_ALERT_TIMEOUT
    ? parseInt(process.env.REACT_APP_ALERT_TIMEOUT)
    : 5000,
  FEATURE_FLAG_AUDIO_BACKEND: false,
  INTERCEPTION_REASONS_TIMER:
    parseInt(readEnvVariable('INTERCEPTION_REASONS_TIMER')) || 15,
  INTERCEPTION_OTHER_REASON_TIMER:
    parseInt(readEnvVariable('INTERCEPTION_OTHER_REASON_TIMER')) || 15,
  FINISH_ORDER_TIMER: parseInt(readEnvVariable('FINISH_ORDER_TIMER')) || 20,
  AGENT_INACTIVITY_TIMER:
    parseInt(readEnvVariable('AGENT_INACTIVITY_TIMER')) || 15,
  ORDER_SUBMISSION_ALERT_TIMEOUT: process.env
    .REACT_APP_ORDER_SUBMISSION_ALERT_TIMEOUT
    ? parseInt(process.env.REACT_APP_ORDER_SUBMISSION_ALERT_TIMEOUT)
    : 3000,
  FEATURE_FLAG_AUTO_COMBO: false,
  MU_MENU_API: readEnvVariable('MU_MENU_API') || '',
  MU_MENU_API_TOKEN: readEnvVariable('MU_MENU_API_TOKEN') || '',
  UNIFIED_MENU_RESTAURANTS: [],
};

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<Partial<ConfigState>>) => {
      //This reducer is really not needed as environment variables which are passed are not changed during runtime
      Object.assign(state, action.payload);
    },
  },
});

export const { update } = configSlice.actions;
export default configSlice.reducer;
