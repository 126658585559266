import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  sortModGroups,
} from '../utils/menu';
import { CartModifierGroup } from '../utils/cart';
import { ParsedMenuItem, ParsedModifierGroup } from '../types/menu';

export interface DialogState {
  step: number;
  steps: string[];
  selectedItem: ParsedMenuItem | undefined;
  selectedItemCartId: number;
  invalidModGroupDescriptions: string[];
  voiceBoardValue: string;
  intentsStatus: boolean[];
  nextSelectedModGroupIndex: number;
  selectedModGroup?: ParsedModifierGroup;
}
const initialState: DialogState = {
  step: 0,
  selectedItem: undefined,
  selectedItemCartId: 0,
  invalidModGroupDescriptions: [],
  voiceBoardValue: '',
  steps: [
    'What can I get for you today?',
    'Sure thing!',
    'Thanks, please pull up to the next window!',
  ],
  intentsStatus: [],
  nextSelectedModGroupIndex: 0,
};
const dialogSlice = createSlice({
  name: 'dialog',
  initialState: initialState,
  reducers: {
    updateSelectedItem: (
      state,
      action: PayloadAction<
        { item: ParsedMenuItem; itemCartId: number } | undefined
      >
    ) => {
      if (action.payload) {
        state.selectedItem = action.payload.item;
        state.selectedItemCartId = action.payload.itemCartId;
      } else {
        state.selectedItem = undefined;
        state.selectedItemCartId = 0;
      }
    },
    cloneSelectedItem: (
      state,
      action: PayloadAction<{ cartItemId: number }>
    ) => {
      state.selectedItemCartId = action.payload.cartItemId;
      state.nextSelectedModGroupIndex = 0;
      state.selectedModGroup = undefined;
    },
    resetDialogSelectedItem: (state) => {
      state.selectedItem = undefined;
      state.selectedItemCartId = 0;
    },
    addInvalidModGroupDescriptions: (
      state,
      action: PayloadAction<string[]>
    ) => {
      state.invalidModGroupDescriptions.concat(action.payload);
    },
    increaseStep: (state) => {
      state.step += 1;
    },
    setStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    setVoiceBoardValue: (state, action: PayloadAction<string>) => {
      state.voiceBoardValue = action.payload;
    },
    initIntentsStatus: (state, action: PayloadAction<boolean[]>) => {
      state.intentsStatus = action.payload;
    },
    setIntentsStatus: (
      state,
      action: PayloadAction<{ idx: number; status: boolean }>
    ) => {
      state.intentsStatus[action.payload.idx] = action.payload.status;
    },
    updateSelectedModGroup: (
      state,
      action: PayloadAction<ParsedModifierGroup | CartModifierGroup | undefined>
    ) => {
      const selectedModGroup = action.payload;
      if (selectedModGroup && state.selectedItem) {
        state.selectedModGroup = selectedModGroup;
        const sortedSelectedModGroups = sortModGroups(state.selectedItem);
        const currentSelectedModGroupIndex = sortedSelectedModGroups.findIndex(
          (modGroup) => modGroup.id === selectedModGroup.id
        );
        state.nextSelectedModGroupIndex =
          currentSelectedModGroupIndex > -1
            ? currentSelectedModGroupIndex + 1
            : 0;
      } else {
        state.selectedModGroup = undefined;
        state.nextSelectedModGroupIndex = 0;
      }
    },
    setModGroupTabIndex: (state, action: PayloadAction<number>) => {
      state.nextSelectedModGroupIndex = action.payload;
    },
  },
});

export const dialogActions = dialogSlice.actions;
export const { resetDialogSelectedItem } = dialogActions;
export default dialogSlice.reducer;
