import { considerTimePeriodCategory } from './menu';
import { Category } from '../generated-interfaces/graphql';
import {
  IBrandMenu,
  ICategoryWithMealPeriod,
  IMUItem,
  IOrderConstraint,
  IOrderItem,
  IRestaurantMealPeriod,
  IRestaurantUnavailableEntity,
} from '../types/brandMenu';
import { CartItem, CartModifierGroup } from './cart';
import { CartItemAdditionTypes } from '../constants/event';
import {
  convertToMapUsingName,
  getRestaurantGroups,
  getTopLevelItems,
  parseCategoryAndMealPeriods,
} from './menuUnification.utils';
import { UPSELL } from '../constants';
import {
  UNIFIED_MENU_CODE_NAME_MAPPING,
  UNIFIED_MENU_MOD_SYMBOL_MAPPING,
} from './menuUnification.constants';

export const convertToBrandMenuCategories = (
  categories: Category[],
  brandMenu: IBrandMenu
) => {
  const brandMenuItems: string[] = [];
  brandMenu.items.forEach((item: IMUItem) => brandMenuItems.push(item.name));
  return [
    { ...categories[0], name: 'Support Brand Menu', menuItems: brandMenuItems },
  ];
};

export const getMenuModuleConstraints = (
  children: CartModifierGroup,
  cartItemsQuantity: Record<string, number>
) => {
  const selectedItems = Object.values(children.selectedItems).reduce(
    (acc, item) => {
      acc.push(convertToMenuModuleOrder(item, cartItemsQuantity, true));
      return acc;
    },
    [] as IOrderItem[]
  );
  return {
    constraint_name: children.name,
    items: selectedItems as IOrderItem[],
  } as IOrderConstraint;
};

export const fillConstraints = (
  cartItem: CartItem,
  cartItemsQuantity: Record<string, number>,
  isModifier: boolean = false
) => {
  const { childModifierGroups, modcode = '', addedBy } = cartItem;

  if (Object.values(childModifierGroups).length) {
    return Object.values(childModifierGroups).reduce((acc, child) => {
      acc.push(getMenuModuleConstraints(child, cartItemsQuantity));
      return acc;
    }, [] as IOrderConstraint[]);
  } else if (modcode) {
    return [
      {
        constraint_name: 'ingredient_qty',
        items: [
          {
            item: UNIFIED_MENU_CODE_NAME_MAPPING[modcode]?.name,
            quantity: isModifier
              ? 1
              : cartItemsQuantity[cartItem.cartItemId] || 1,
            added_by: addedBy || CartItemAdditionTypes.human,
          },
        ],
      },
    ];
  }

  return undefined;
};

export const convertToMenuModuleOrder = (
  cartItem: CartItem,
  cartItemsQuantity: Record<string, number>,
  isModifier: boolean = false
) => {
  return {
    item: cartItem.name,
    quantity: isModifier ? 1 : cartItemsQuantity[cartItem.cartItemId] || 1,
    constraints: fillConstraints(cartItem, cartItemsQuantity, isModifier),
    added_by: cartItem.addedBy || CartItemAdditionTypes.human,
  } as IOrderItem;
};

export const buildUnifiedMenuModuleOrder = (
  cartItem: CartItem,
  cartItemsQuantity: Record<string, number>,
  isModifier: boolean = false
) => {
  const menuModuleItems = convertToMenuModuleOrder(
    cartItem,
    cartItemsQuantity,
    isModifier
  );
  if (cartItem.name.includes(UPSELL)) {
    return menuModuleItems?.constraints?.[0]?.items?.[0];
  }
  return menuModuleItems;
};

export const processBrandMenu = (
  brandMenu: IBrandMenu,
  restaurantCode: string,
  restaurantUnavailableEntities: IRestaurantUnavailableEntity[],
  formattedRestaurantMealPeriods: IRestaurantMealPeriod[],
  timezone: string = ''
) => {
  // Get unavailable items from both brand menu unavailable entities  and restaurant unavailable entities
  const brandUnavailableEntities = brandMenu.unavailable_entities;
  const restaurantGroups = getRestaurantGroups(
    restaurantCode,
    brandMenu.restaurant_groups
  );
  const unavailableItemsByName = {
    ...convertToMapUsingName(
      brandUnavailableEntities.filter((unavailableEntity) =>
        restaurantGroups.includes(unavailableEntity.restaurant_group)
      )
    ),
    ...convertToMapUsingName(restaurantUnavailableEntities),
  };

  const items = convertToMapUsingName(brandMenu.items);

  // Process the meal period in items to get category with time period
  const restaurantMealPeriodsByName = convertToMapUsingName(
    formattedRestaurantMealPeriods
  );

  const categoriesWithMealPeriod: ICategoryWithMealPeriod[] =
    brandMenu.categories.reduce((acc, category) => {
      if (!category?.item_names) return acc;

      const mealPeriodsSet = new Set<string>(
        category.item_names
          .map((itemName) => items[itemName]?.meal_period)
          .filter((mealPeriod): mealPeriod is string => !!mealPeriod)
      );

      acc.push({
        ...category,
        mealPeriods: Array.from(mealPeriodsSet),
      });

      return acc;
    }, [] as ICategoryWithMealPeriod[]); // Adjust the type if necessary

  const { categoriesWithTimePeriod, alwaysAvailableCategories } =
    parseCategoryAndMealPeriods(
      categoriesWithMealPeriod,
      restaurantMealPeriodsByName
    );

  const availableCategoryWithTimePeriod = timezone
    ? considerTimePeriodCategory(categoriesWithTimePeriod, timezone)
    : categoriesWithTimePeriod;

  // Populate the top level menu items using the active categories and unavailable items
  const topLevelMenuItems = getTopLevelItems(
    [...availableCategoryWithTimePeriod, ...alwaysAvailableCategories],
    brandMenu,
    unavailableItemsByName
  );

  return {
    topLevelMenuItems,
    brandMenu,
    restaurantUnavailableEntities,
    categoriesWithTimePeriod,
    availableCategoryWithTimePeriod,
    alwaysAvailableCategories,
    unavailableItemsByName,
    // Hardcode the following fields for compatibility
    processedCategoryAndTimePeriodJSON: {},
    processedMenuJSON: {},
    processedModGroupsJSON: {},
    persistentVoiceProps: {},
    codeNameMapping: UNIFIED_MENU_CODE_NAME_MAPPING,
    modSymbolMapping: UNIFIED_MENU_MOD_SYMBOL_MAPPING,
    unavailableItems: {},
    autoComboPrpIds: [],
    comboPrpIdToALaCartePrpId: {},
  };
};
