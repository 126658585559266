import fuzzysort from 'fuzzysort';
import { CartItem, CartModifierGroup } from './cart';
import { DUMMY_MOD_SYMBOL } from './constants';
import { ModSymbolCodeNameMappingType } from './mappings';
import { TopLevelMenuItem } from './menu';
import { GenericMap } from './types';
import { getMenuUnificationFeatureFlag } from './session-storage';
import { ParsedMenuItem, ParsedModifierGroup } from '../types/menu';

export function getModSymbolFromInput(
  value: string,
  modSymbolMapping: ModSymbolCodeNameMappingType
) {
  const firstWord = value.split(' ')[0];

  const modsymbols =
    firstWord === DUMMY_MOD_SYMBOL
      ? DUMMY_MOD_SYMBOL
      : Object.keys(modSymbolMapping).filter((s) => firstWord.startsWith(s));

  if (!modsymbols.length) return undefined;

  let longestMatch = modsymbols[0];

  for (const modsymbol of modsymbols) {
    if (modsymbol.length > longestMatch.length) {
      longestMatch = modsymbol;
    }
  }

  return longestMatch;
}

export const getQuantityFromInput = (
  value: string,
  modSymbolMapping: ModSymbolCodeNameMappingType
) => {
  if (!value) return { quantity: null, inputValue: '', modSymbol: null };
  const firstPartNum = parseInt(value.split(' ')[0]);
  const modSymbol = getModSymbolFromInput(value, modSymbolMapping);

  if (Number.isNaN(firstPartNum)) {
    if (modSymbol) {
      let result = {
        quantity: null,
        inputValue: value.replace(modSymbol, '')?.trim(),
        modSymbol,
      };
      return result;
    }
    return { quantity: null, inputValue: value?.trim(), modSymbol: null };
  } else {
    const inputValue = value.slice(firstPartNum.toString().length + 1)?.trim();
    return {
      quantity: firstPartNum,
      inputValue,
      modSymbol: null,
    };
  }
};

export const iterateModGroups = (
  childrenMods: GenericMap<CartModifierGroup>,
  groupsToCheck: ParsedModifierGroup[],
  menuItem: ParsedMenuItem,
  isChecked: boolean,
  inputModSymbol: string
) => {
  const children = Object.values(childrenMods || {})
    .flatMap((child) => Object.values(child.selectedItems))
    .flatMap(
      ({
        id,
        modifierGroups,
        childModifierGroups,
        modcode,
        parentModifierGroupId,
      }) => {
        if (
          id === menuItem.id &&
          parentModifierGroupId === menuItem.parentModifierGroupId &&
          (modcode === inputModSymbol || inputModSymbol === '.')
        ) {
          // Check modcode before unselecting a modifier
          isChecked = false;
        }
        isChecked = iterateModGroups(
          childModifierGroups,
          groupsToCheck,
          menuItem,
          isChecked,
          inputModSymbol
        );
        return Object.values(modifierGroups);
      }
    );
  groupsToCheck.push(...children);
  return isChecked;
};

export const getOptionLabel = (
  option: TopLevelMenuItem | string | ParsedMenuItem
) => {
  if (typeof option === 'string') {
    return option;
  }
  return option.name;
};

export const getHightlightWithQuantity = (
  quantity: number | null,
  highlight: string,
  inputValue: string
) => {
  if (
    quantity &&
    highlight.includes(quantity.toString()) &&
    !inputValue.includes(quantity.toString())
  ) {
    return `1x ${highlight}`;
  }
  return `${quantity || 1}x ${highlight}`;
};

export const getHightlight = (
  inputValue: string,
  menuItem: ParsedMenuItem | TopLevelMenuItem,
  quantity: number | null
) => {
  let highlight = menuItem.name;
  const results = fuzzysort.single(inputValue, menuItem.name);
  if (results) {
    highlight = results.highlight() || menuItem.name;
  }

  if (!getMenuUnificationFeatureFlag()) {
    if (menuItem.synonyms) {
      let synonymResult: Fuzzysort.Result | null = null;
      const synonyms =
        typeof menuItem.synonyms === 'string'
          ? JSON.parse(menuItem.synonyms)
          : menuItem.synonyms;

      synonyms.every((synonym: any) => {
        synonymResult = fuzzysort.single(inputValue, synonym);
        if (synonymResult) {
          highlight = `${highlight} [${synonymResult.highlight()}]`;
        }
        return !synonymResult;
      });
    }
  }

  const isPrompt = menuItem.category === 'tts-prompt';
  const isModifier = menuItem.category === 'modifier';
  if (isPrompt) {
    highlight = `${highlight}`;
  } else if (!isModifier) {
    highlight = getHightlightWithQuantity(quantity, highlight, inputValue);
  }
  return highlight;
};

export const getFilteredOptions = (
  modSymbol: string | null,
  inputValue: string,
  quantity: number | null,
  options: (TopLevelMenuItem | ParsedMenuItem)[],
  orignalInputValue: string
): (ParsedMenuItem | TopLevelMenuItem)[] => {
  if (modSymbol === DUMMY_MOD_SYMBOL && !inputValue) {
    return options.slice(0, 50);
  }

  // Filter out options not starting with '/' if inputValue starts with it
  const results = fuzzysort.go(
    inputValue,
    orignalInputValue.startsWith('/')
      ? options.filter((option: TopLevelMenuItem | ParsedMenuItem) =>
          option.name.startsWith('/')
        )
      : options,
    {
      keys: ['name', 'category', 'synonyms'],
    }
  );

  let resultsIdObj: Record<string, boolean> = {};
  const resultObjList = results.map((v) => {
    resultsIdObj[v.obj.id] = true;
    return v.obj;
  });

  // When input has quantity
  if (quantity) {
    const resultsForOrignalInput = fuzzysort.go(orignalInputValue, options, {
      keys: ['name', 'category'],
    });
    resultsForOrignalInput.forEach((v) => {
      if (!resultsIdObj[v.obj.id]) {
        resultObjList.unshift(v.obj);
      }
    });
  }

  return resultObjList.slice(0, 50);
};

export const processQuantity = (
  inputQuantity: number,
  value: ParsedMenuItem | TopLevelMenuItem,
  currentMessage: string
) => {
  if (inputQuantity > 1) {
    const quantityOccurance = (
      currentMessage.match(new RegExp(inputQuantity.toString(), 'g')) || []
    ).length;
    if (
      value.name.includes(inputQuantity.toString()) &&
      quantityOccurance === 1
    ) {
      // Handle menu item with number in the name. i.e. #7 mushroom burge
      return 1;
    }
  }
  return inputQuantity;
};

export const findAnotherSelectedItem = (
  cartItems: GenericMap<CartItem>,
  selectedItemCartId: number
) => {
  const cartItemIds = Object.keys(cartItems)
    .filter((id) => id !== selectedItemCartId.toString())
    .map((id) => parseInt(id));
  return Math.max(...cartItemIds);
};
