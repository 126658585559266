import { Gauge, Attributes } from '@opentelemetry/api';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { resetHealthScoreData } from '../redux/features/healthScore/healthScore.slice';
import { selectHealthScore } from '../redux/features/healthScore/healthScore.selector';
import useInterval from './useInterval.hooks';
import { findAverageOfArray } from '../utils';
import logger from '../utils/logger';

const useHealthScore = (healthMetric: {
  healthMetric?: Gauge<Attributes>;
  record?: any;
}) => {
  const dispatch = useAppDispatch();
  const { criticalChecks, nonCriticalChecks } =
    useAppSelector(selectHealthScore);

  const calculateHealthScore = () => {
    let criticalCheckScore = 1;
    let nonCriticalCheckScore = 1;

    const criticalChecksArray = Object.values(criticalChecks).flat();
    if (
      criticalChecksArray.length &&
      criticalChecksArray.findIndex((entry) => entry === 0) >= 0
    ) {
      criticalCheckScore = 0;
    }

    const nonCriticalChecksArray = Object.values(nonCriticalChecks);

    if (nonCriticalChecksArray.flat().length) {
      const weight = 1 / nonCriticalChecksArray.length;

      nonCriticalCheckScore = nonCriticalChecksArray.reduce((acc, entry) => {
        const avg = entry.length ? findAverageOfArray(entry) : 1;

        return acc + weight * avg;
      }, 0);
    }

    return Math.ceil(70 * criticalCheckScore + 30 * nonCriticalCheckScore);
  };

  const sendHealthScore = () => {
    const healthScore = calculateHealthScore();

    logger.log({ message: `Health Score is: ${healthScore}` });
    healthMetric.record(healthScore);

    dispatch(resetHealthScoreData());
  };

  useInterval(() => {
    sendHealthScore();
  }, 900000);
};

export default useHealthScore;
