import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMenuUnificationSlice } from './menuUnification.props';
import { ISendItemProperties } from '../../../reducers/orderSlice.props';


const initialState: IMenuUnificationSlice = {
  menuUnificationOrderItems: [],
  restaurantGroups: [],
};

const menuUnificationSlice = createSlice({
  name: 'menuUnification',
  initialState,
  reducers: {
    resetMenuUnification: () => {
      return { ...initialState };
    },
    updateMenuUnificationOrderItems: (
      state,
      { payload }: PayloadAction<ISendItemProperties[]>
    ) => {
      state.menuUnificationOrderItems = payload;
    },
    setRestaurantGroups: (state, { payload }: PayloadAction<string[]>) => {
      state.restaurantGroups = payload;
    },
  },
});

export const { resetMenuUnification, updateMenuUnificationOrderItems, setRestaurantGroups } =
  menuUnificationSlice.actions;
export default menuUnificationSlice.reducer;
