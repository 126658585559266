import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';

const selectHealthScore = (state: RootState) => state.healthScore;

const selectCriticalHealthChecks = createSelector(
  selectHealthScore,
  (healthScore) => healthScore.criticalChecks
);

const selectNonCriticalHealthChecks = createSelector(
  selectHealthScore,
  (healthScore) => healthScore.nonCriticalChecks
);

export {
  selectHealthScore,
  selectCriticalHealthChecks,
  selectNonCriticalHealthChecks,
};
